/**
 * @fileOverview
 * @name actionRetryQueue.ts
 * @author Taketoshi Aono
 * @license
 */

import { FetchFailure } from './fetchService';

enum Status {
  STOPED = 0,
  RUNNING = 1,
}

type QueueTuple = [
  string,
  (() => Promise<any>) | undefined,
  boolean,
  { max: number; current: number }
];

export const createRetryHandler = <T>() => {
  let status = Status.STOPED;
  const queue: QueueTuple[] = [];
  const map: { [key: string]: number } = {};
  const callbackHandlers = new Map<string, (a: any) => void>();
  const drainQueue = async () => {
    await queue.slice().reduce(async (p, [id, action, isCheckOnline, challenge], i) => {
      await p;
      if (!action) {
        return;
      }
      if (isCheckOnline && !navigator.onLine) {
        return;
      }
      try {
        if (challenge.max > challenge.current) {
          challenge.current++;
          const result = await action();
          callbackHandlers.get(id)?.(result);
          callbackHandlers.delete(id);
        }
        queue.splice(i, 1);
        delete map[id];
      } catch (e) {
        console.error(e);
      }
      if (challenge.max === challenge.current) {
        throw new Error('Retry failed');
      }
      return;
    }, Promise.resolve());

    if (queue.length) {
      queue.forEach(([id], idx) => {
        map[id] = idx;
      });
      return new Promise<void>(async resolve => {
        setTimeout(async () => {
          await drainQueue();
          resolve();
        }, 2000);
      });
    } else {
      status = Status.STOPED;
    }
  };

  return async (
    seqId: string | number,
    action: () => Promise<any>,
    { numRetry = 500, isCheckOnline = true }: { numRetry?: number; isCheckOnline?: boolean } = {}
  ) => {
    return new Promise(async (resolve: (value: T) => void) => {
      try {
        resolve(await action());
      } catch (e) {
        // 403権限エラーの場合はリトライしない
        if (e instanceof FetchFailure && e.status === 403) {
          return;
        }
        const id = `${seqId}`;
        const curIdx = queue.length;
        const entry = map[id];
        const newEntry: QueueTuple = [id, action, isCheckOnline, { max: numRetry, current: 0 }];
        callbackHandlers.set(id, resolve);
        if (entry) {
          queue[entry] = newEntry;
        } else {
          queue.push(newEntry);
          map[`${id}`] = curIdx;
        }
        if (status !== Status.RUNNING) {
          status = Status.RUNNING;
          await drainQueue();
        }
      }
    });
  };
};
